import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';
import { FormattedMessage } from 'react-intl';
import SectionTitle from './Atoms/SectionTitle';
import Paragraph from './Paragraph';
import theme from '../assets/styles/theme';
import Box from './Atoms/Box';

const StyledSection = styled.section`
  position: relative;
  margin: auto;
  background: ${props => props.theme.color.green};
  color: ${props => props.theme.color.white};
  ${space}
  h2 {
    text-transform: uppercase;
    font-weight: 700;
    padding-bottom: 16px;
  }
`;

const StyledLinkedIn = styled.div`
  width: 32px;
  height: 32px;
  color: ${props => props.theme.color.white};
  svg {
    margin: -4px;
  }
`;

const Contact = ({
  sectionTitle, heading, companyName, address, email,
}) => (
  <StyledSection className="section" py={[8, 8, 9]} id="contact">
    <div className="container">
      <div className="columns">
        <div className="column is-2 is-offset-1 is-offset-2-desktop">
          <SectionTitle
            color={theme.color.gold}
            borderTop={`1px solid ${theme.color.gold}`}
          >
            {sectionTitle}
          </SectionTitle>
        </div>
        <div className="column">
          {/* <Heading fontSize={[4, 4, 6]} mb={[3, 3, 4]} style={{ textTransform: 'none' }}> */}
          {/*  {heading} */}
          {/* </Heading> */}
          <Paragraph color={theme.color.gold}>
            <FormattedMessage id="contact.email.title" defaultMessage="Email" />
          </Paragraph>
          <Paragraph pb={[3, 3, 4]}>
            <a href={`mailto:${email}`}>
              {email}
            </a>
          </Paragraph>
          <Paragraph color={theme.color.gold}>
            <FormattedMessage id="contact.address.title" defaultMessage="Address" />
          </Paragraph>
          <Box position="relative">
            <Paragraph style={{ color: 'transparent' }}>
              {address}
            </Paragraph>
            <Paragraph style={{ zIndex: 1, position: 'absolute', top: 0 }}>
              {address}
            </Paragraph>
          </Box>
          <Box pb={[5, 5, 6]}>
            <a href="https://www.linkedin.com/company/cambiumgrovecapital" target="_blank">
              <StyledLinkedIn className="linkedin-follow-company">
                <svg fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                  <path d="M 7.5 5 C 6.1309372 5 5 6.1309372 5 7.5 L 5 24.5 C 5 25.869063 6.1309372 27 7.5 27 L 24.5 27 C 25.869063 27 27 25.869063 27 24.5 L 27 7.5 C 27 6.1309372 25.869063 5 24.5 5 L 7.5 5 z M 7.5 7 L 24.5 7 C 24.786937 7 25 7.2130628 25 7.5 L 25 24.5 C 25 24.786937 24.786937 25 24.5 25 L 7.5 25 C 7.2130628 25 7 24.786937 7 24.5 L 7 7.5 C 7 7.2130628 7.2130628 7 7.5 7 z M 10.4375 8.71875 C 9.4875 8.71875 8.71875 9.4885 8.71875 10.4375 C 8.71875 11.3865 9.4865 12.15625 10.4375 12.15625 C 11.3855 12.15625 12.15625 11.3865 12.15625 10.4375 C 12.15625 9.4895 11.3855 8.71875 10.4375 8.71875 z M 19.46875 13.28125 C 18.03375 13.28125 17.0815 14.0665 16.6875 14.8125 L 16.625 14.8125 L 16.625 13.5 L 13.8125 13.5 L 13.8125 23 L 16.75 23 L 16.75 18.3125 C 16.75 17.0745 16.99625 15.875 18.53125 15.875 C 20.04425 15.875 20.0625 17.274 20.0625 18.375 L 20.0625 23 L 23 23 L 23 17.78125 C 23 15.22525 22.45575 13.28125 19.46875 13.28125 z M 9 13.5 L 9 23 L 11.96875 23 L 11.96875 13.5 L 9 13.5 z" />
                </svg>
              </StyledLinkedIn>
            </a>
          </Box>
        </div>
      </div>
    </div>
  </StyledSection>
);

export default Contact;
