import React, { Fragment } from 'react'
import styled from 'styled-components';
import { space } from 'styled-system';
import Paragraph from './Paragraph';
import Box from './Atoms/Box';
import theme from '../assets/styles/theme';
import Heading from './Atoms/Heading';
import SectionTitle from './Atoms/SectionTitle';
import LeftLine from '../assets/icons/leftline-2.svg';

const StyledSection = styled.section`
  position: relative;
  overflow: hidden;
  margin: auto;
  background: ${props => props.theme.color.green};
  color: white;
  ${space}
  h2 {
    font-size: 24px;
    padding-bottom: 24px;
  }
`;

const Introduction = ({
  sectionTitle, heading, title, desc,
}) => {
  const descArr = desc.split(/\r?\n/);

  return (
    <StyledSection className="section" py={[8, 8, 9]} id="about">
      <Box
        width={[100, 100, 200]}
        height="auto"
        position="absolute"
        left={-4}
        top={0}
      >
        <LeftLine />
      </Box>
      <div className="container">
        <div className="columns">
          <div className="column is-2 is-offset-1 is-offset-2-desktop">
            <SectionTitle color={theme.color.gold} borderTop={`1px solid ${theme.color.gold}`}>
              {sectionTitle}
            </SectionTitle>
          </div>
          <div className="column">
            <Heading fontSize={[22, 22, 36]} mb={[2, 2, 3]}>
              {heading}
            </Heading>
            <Box maxWidth={480}>
              {descArr && descArr.map(sub => (
                <Fragment key={sub}>
                  {!sub && <Paragraph pb={[4, 4, 5]} />}
                  {sub && sub !== '' && <Paragraph pb={0}>{sub}</Paragraph>}
                </Fragment>
              ))}
            </Box>
          </div>
        </div>
      </div>
    </StyledSection>
  );
};

export default Introduction;
