import React from 'react';
import Box from './Box';
import theme from '../../assets/styles/theme';

const SectionTitle = ({ children, ...rest }) => (
  <Box
    mt={1}
    pt={2}
    display="inline-block"
    borderTop={`1px solid ${theme.color.gray}`}
    style={{ textTransform: 'uppercase' }}
    {...rest}
  >
    {children}
  </Box>
);

export default SectionTitle;
