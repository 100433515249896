import React from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';
import Box from './Atoms/Box';
import theme from '../assets/styles/theme';
import SectionTitle from './Atoms/SectionTitle';
import Image from './Image';
import Paragraph from './Paragraph';
import Heading from './Atoms/Heading';

const StyledSection = styled.section`
  position: relative;
  overflow: hidden;
  margin: auto;
  background: ${props => props.theme.color.black80};
  color: white;
  ${space}
  .showcase-box {
    @media screen and (max-width: 767px) {
      & {
        max-width: 350px;
        margin-left: auto;
        margin: auto;
      }
    }
    .gatsby-image-wrapper {
      max-width: 260px;
      margin: auto;
    }
    p {
      max-width: 300px;
      margin: auto;
      opacity: 0.9;
    }
  }
  h2 {
    font-size: 24px;
    padding-bottom: 24px;
  }
`;

const Showcase = ({
  sectionTitle, companies,
}) => (
  <StyledSection className="section" py={[7, 7, 8]} id="showcase">
    <div className="container">
      <div className="columns">
        {/* <div className="column is-8 is-offset-2 has-text-centered"> */}
        <div className="column is-2 is-offset-1 is-offset-2-desktop">
          <SectionTitle
            color={theme.color.gold}
            borderTop={`1px solid ${theme.color.gold}`}
          >
            {sectionTitle}
          </SectionTitle>
        </div>
        <div className="column">
          <Heading color={theme.color.white} fontSize={[22, 22, 36]} mb={[2, 2, 3]}>
            Portfolio Companies
          </Heading>
        </div>
      </div>
      <Box className="columns" mx={[-16, -16, -16, -40, -140]}>
        <div className="column">
          <Box maxWidth="90%" m="auto">
            <div className="columns is-multiline">
              {companies.map(company => (
                <Box key={company.desc} className="showcase-box column is-6 is-10-mobile is-6-tablet is-offset-1-mobile is-4-desktop is-4-fullhd" px={[4, 4, 6, 2]} py={[5, 5, 6]}>
                  <Image imageInfo={company.logo} />
                  <Paragraph className="has-text-centered" pt={[2, 2, 3]}>{company.desc}</Paragraph>
                </Box>
              ))}
            </div>
          </Box>
        </div>
      </Box>
    </div>
  </StyledSection>
);

export default Showcase;
