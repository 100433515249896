import React from 'react';
import styled from 'styled-components';
import {
  space, fontSize, opacity, color,
} from 'styled-system';

const StyledParagraph = styled.p`
  ${opacity}
  ${fontSize}
  ${space}
  ${color}
`;

const Paragraph = ({ children, ...rest }) => (
  <StyledParagraph fontSize={[1, 2, 3]} pb={[2, 2, 3]} {...rest}>
    {children}
  </StyledParagraph>
);

export default Paragraph;
