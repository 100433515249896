import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import Hero from '../components/Hero';
import Introduction from '../components/Introduction';
import Contact from '../components/Contact';
import line from '../assets/images/rightline-full-cut.png';
import Box from '../components/Atoms/Box';
import Credit from '../components/Credit';
import Special from '../components/Special';
import Showcase from '../components/Showcase';

const StyledBox = styled(Box)`
  overflow: hidden;
  position: absolute;
  //height: 100%;
  //img {
  //  height: auto;
  //  width: 100%;
  //}
`;


const IndexPage = ({ data }) => {
  const {
    companyName, logo, bgImage, hero, intro, credit, contact, showcase, special
  } = data.markdownRemark.frontmatter;
  return (
    <div>
      <SEO title="Home" />
      <Hero logo={logo} bgImage={bgImage} {...hero} />
      <Box position="relative" overflowHidden>
        <Introduction {...intro} />
        <Credit {...credit} />
        <Showcase {...showcase} />
        <Special {...special} />
        <Contact companyName={companyName} {...contact} />
        <StyledBox
          right={['-20%', '-12%', '0%']}
          width={[330, 440, 470, 470]}
          bottom={[0]}
          opacity={[0.8]}
        >
          <img alt="" src={line} />
          {/* <RightLine /> */}
        </StyledBox>
      </Box>
    </div>
  );
};

export default IndexPage;

// ********************************* Data graphql *********************************
export const pageQuery = graphql`
  query HomePageWithId($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        heading
        subheading
        description
        companyName
        logo {
          alt
          image {
            childImageSharp {
              fluid(maxWidth: 600, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        bgImage {
          alt
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        hero {
          heading
          subheading
          desc
        }
        intro {
          sectionTitle
          heading
          title
          desc
        }
        credit {
          sectionTitle
          heading
          desc
        }
        showcase {
          sectionTitle
          companies {
            name
            desc
            logo {
              alt
              image {
                childImageSharp {
                  fluid(maxWidth: 600, quality: 90) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
        special {
          sectionTitle
          heading
          desc
        }
        contact {
          sectionTitle
          heading
          address
          email
        }
      }
    }
  }
`;
