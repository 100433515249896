import React from 'react';
import styled from 'styled-components';
import { space, fontSize } from 'styled-system';

const StyledHeading = styled.h1`
    font-family: "Suprema", sans-serif;
    text-transform: uppercase;
    font-weight: 800;
    ${fontSize}
    ${space}
`;

const Heading = ({ children, ...rest }) => (
  <StyledHeading {...rest}>
    {children}
  </StyledHeading>
);

export default Heading;
