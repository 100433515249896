import React from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';
import Paragraph from './Paragraph';
import hero from '../assets/images/hero.png';
import logo from '../assets/images/logo_white@33.33x.png';
import Arrow from '../assets/icons/arrow.svg';
import Box from './Atoms/Box';
import theme from '../assets/styles/theme';
import Heading from './Atoms/Heading';

const HeroSection = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  background-color: ${props => props.theme.color.green};
  background-image: ${props => `url(${hero})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
  .wrapper {
    max-width: 700px;
    z-index: 1;
  }
  .dark-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.3);
  }
  .logo-box {
    width: 90%;
    margin: auto;
    padding-bottom: 8px;
  }
  ${space}
`;

const GreenCover = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(29, 60, 52, 0.8);
`;

const BouncingBox = styled(Box)`
  animation: bouncing 1s infinite;
`


const Hero = ({
  bgImage, heading, subheading, desc,
}) => (
  <HeroSection py={[8, 8, 9]} bgImage={bgImage}>
    {/* <GreenCover /> */}
    {/* <div className="dark-bg" /> */}
    <div className="wrapper">
      <Box className="logo-box" px={[3, 3, 1]}>
        <img alt={heading} src={logo} />
      </Box>
      {/*<Heading fontSize={[5, 5, 7]} mb={[1, 1, 2]}>*/}
      {/*  {heading}*/}
      {/*</Heading>*/}
      {/* <Paragraph fontSize={[1, 1, 2]}> */}
      {/*  {subheading} */}
      {/* </Paragraph> */}
      <Paragraph fontSize={[1, 1, 2]} px={4} color={theme.color.gold}>
        {desc}
      </Paragraph>
    </div>
    <BouncingBox position="absolute" textAlign="center" width={[16, 16, 24]} bottom={[2, 2, 5]}>
      <Arrow />
    </BouncingBox>
  </HeroSection>
);

export default Hero;
